.loading {
    grid-row: 3;
    grid-column: 2;
    width: 100%;
    height: 100%;
    background-color: #85AD1E;
    display: grid;
    align-items: center;
    justify-items: center;
    row-gap: 10px;
}

.message {
    align-self: flex-start;
    font-family: Lato;
    font-weight: 700;
    font-size: 25px;
    color: #FFFFFF;
    text-align: center;
}

.error {
    font-weight: 500;
    font-size: 25px;
    color: #C0372A;
    text-align: center;
}

.flower_spinner {
    align-self: flex-end;
}