.gallery_view_0, .gallery_view_1, .gallery_view_2, .gallery_view_3 {
    --container-width: 23.83%;
    --container-height: 100%;
}

.gallery_view_4 {
    --container-width: 100%;
    --container-height: 31.4%;
}

.gallery_view {
    position: relative;
    width: var(--width);
    height: var(--height);
    display: flex;
    gap: 1.56%;
    justify-content: center;
}

.gallery_view div {
    width: var(--container-width);
    height: var(--container-height);
}

.gallery_view {
    width: 82.23%;
    height: 26.58%;
    column-gap: 1.56%;
    position: relative;
    display: flex;
    align-content: center;
    justify-content: center;
}

.gallery_view_4 {
    width: 19.59%;
    height: 84.66%;
    row-gap: 2.9%;
    flex-direction: column;
}

.scroll {
    position: absolute;
    width: 46px;
    height: 46px;
    border: none;
    background: #f1f3f500;
    display: grid;
    justify-items: center;
    align-items: center;
}

.scroll:disabled {
    opacity: 0.5;
}

.left {
    left: -46px;
    bottom: calc(50% - 46px / 2);
    transform: rotate(270deg);
}

.right {
    right: -46px;
    bottom: calc(50% - 46px / 2);
    transform: rotate(90deg);
}

.up {
    top: -46px;
    left: calc(50% - 46px / 2);
    transform: rotate(0deg);
}

.down {
    bottom: -46px;
    left: calc(50% - 46px / 2);
    transform: rotate(180deg);
}

.icon {
    width: 60%;
}

.icon path {
    stroke: #2F2F2F;
}