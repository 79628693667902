.discovery_page {
    --discovery-page-padding: 56px;
    --discovery-page-row-gap: 75px;
}

.discovery_page {
    width: 100vw;
    height: 100%;
    display: grid;
    row-gap: var(--discovery-page-row-gap);
    justify-items: center;
    align-content: flex-start;
}

@media(max-width: 1023px) {
    .discovery_page {
        --discovery-page-padding: calc(10px + (32 - 10) * ((100vw - 320px) / (1023 - 320)));
        --discovery-page-row-gap: calc(50px + (75 - 50) * ((100vw - 320px) / (1023 - 320)));
    }
}
