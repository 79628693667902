.video_player {
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-rows: 100%;
    grid-template-columns: 100%;
    overflow: hidden;
}

.play_button {
    grid-row: 1;
    grid-column: 1;
}

.thumbnail {
    grid-row: 1;
    grid-column: 1;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 1;
}

.video {
    grid-row: 1;
    grid-column: 1;
    width: 100%;
    height: 100%;
    object-fit: contain;
    background-color: #000000;
}