.count_down {
    --number-font-size: 48px;
    --unit-font-size: 26px;
}

.count_down {
    display: grid;
    grid-auto-flow: column;
    column-gap: 24px;
    grid-auto-columns: max-content;
}

.count {
    display: grid;
    grid-template-columns: max-content max-content;
    column-gap: 16px;
}

.number {
    padding: 5px 10px;
    font-family: Lato;
    font-weight: 500;
    font-size: var(--number-font-size);
    line-height: calc(var(--number-font-size) + 10px);
    text-align: center;
    color: #FFFFFF;
    border: 1px solid white;
}

.unit {
    align-self: flex-end;
    font-family: Lato;
    font-weight: 500;
    font-size: var(--unit-font-size);
    color: #FFFFFF;
    margin-bottom: 5px
}

@media(max-width: 900px) {
    .count_down {
        grid-auto-columns: 1fr;
    }
    .count {
        grid-template-columns: 1fr;
        row-gap: 8px;
    }
}

@media(max-width: 768px) {
    .count_down {
        --number-font-size: calc(26px + (48 - 26) * ((100vw - 375px) / (768 - 375)));
        --unit-font-size: calc(16px + (26 - 16) * ((100vw - 375px) / (768 - 375)));
    }
}