.learn_more {
    background: transparent;
}

.learn_more>div {
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    border-radius: 0;
    background: rgba(241, 243, 245, 0.95);
    text-align: left;
    color: rgb(0, 0, 0);
    display: grid;
    grid-template-rows: max-content max-content 1fr;
    row-gap: 12px;
    overflow: hidden;
}

.header {
    font-family: Lato;
    font-weight: 500;
    font-size: 24px;
    line-height: 32px;
}

.details {
    max-width: 568px;
    padding-right: 15px;
    font-family: Lato;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    overflow-y: auto;
}

.close_button {
    grid-row: 1;
    justify-self: flex-end;
    border: none;
    background: transparent;
    padding-bottom: 38px;
}

.close_icon path {
    stroke: rgb(0, 0, 0);
}