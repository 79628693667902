.controls {
    position: fixed;
    bottom: 10px;
    left: 50%;
    z-index: 2;
    margin-left: -144px;
    width: max-content;
    height: 44px;
    padding: 0px 10px;
    background: #F1F3F5;
    border-radius: 8px;
    box-shadow: 2px 2px 4px #0000001a;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-auto-flow: column;
    grid-auto-columns: 65px;
    /* transition: opacity 0.5s ease-in-out; */
    /* opacity: 0.2; */
}

/* .controls:hover {
    opacity: 1;
} */