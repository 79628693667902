.hero {
    --height: 681px;
    --backdrop-offset: -89px;
    --deck-font-size: 30px;
    --title-font-size: 60px;
    --body-font-size: 20px;
    --body-margin-T: 15px;
    --video-width: calc(840px - var(--discovery-page-padding));
}

.hero {
    width: 100%;
    max-width: 1680px;
    height: var(--height);
    padding: 0px var(--discovery-page-padding);
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr max-content max-content max-content 1fr;
    position: relative;
}

.deck {
    grid-row: 2;
    grid-column: 1;
    padding-bottom: 8px;
    font-family: Lato;
    font-weight: 300;
    font-size: var(--deck-font-size);
    line-height: 29px;
    text-transform: uppercase;
    color: #686262;
}

.title {
    grid-row: 3;
    grid-column: 1;
    font-family: Lato;
    font-weight: 500;
    font-size: var(--title-font-size);
    line-height: calc(var(--title-font-size) + 10px);
    text-transform: uppercase;
    color: #000000;
}

.body {
    grid-row: 4;
    grid-column: 1;
    margin-top: var(--body-margin-T);
    padding-right: 30%;
    font-family: Lato;
    font-weight: 400;
    font-size: var(--body-font-size);
    line-height: calc(var(--body-font-size) + 6px);
    color: #2F2F2F;
}

.video {
    grid-row: 1/6;
    grid-column: 2;
    justify-self: flex-end;
    align-self: center;
    width: 100%;
    height: calc((9 / 16) * var(--video-width));
}

.backdrop {
    grid-row: 1/6;
    grid-column: 2;
    position: absolute;
    height: 100%;
    right: var(--backdrop-offset);
    z-index: -1;
}

@media(max-width: 1680px) {
    .hero {
        --height: calc(415px + (681 - 415) * ((100vw - 1024px) / (1680 - 1024)));
        --deck-font-size: calc(17px + (30 - 17) * ((100vw - 1024px) / (1680 - 1024)));
        --title-font-size: calc(38px + (60 - 38) * ((100vw - 1024px) / (1680 - 1024)));
        --body-font-size: calc(15px + (20 - 15) * ((100vw - 1024px) / (1680 - 1024)));
        --body-margin-T: calc(10px + (15 - 10) * ((100vw - 1024px) / (1680 - 1024)));
        --backdrop-offset: calc(-54px + (54 - 89) * ((100vw - 1024px) / (1680 - 1024)));
        --video-width: calc((100vw / 2) - var(--discovery-page-padding));
    }
}

@media(max-width: 1023px) {
    .hero {
        --deck-font-size: calc(17px + (30 - 17) * ((100vw - 320px) / (1023 - 320)));
        --title-font-size: calc(25px + (60 - 25) * ((100vw - 320px) / (1023 - 320)));
        --body-font-size: calc(15px + (20 - 15) * ((100vw - 320px) / (1023 - 320)));
        --hero-margin-top: calc(15px + (42 - 15) * ((100vw - 320px) / (1023 - 320)));
        --deck-margin-top: calc(40px + (150 - 40) * ((100vw - 320px) / (1023 - 320)));
        --backdrop-offset: calc(-40px + (40 - 110) * ((100vw - 320px) / (1023 - 320)));
        --video-width: calc(100vw - (2 * var(--discovery-page-padding)));
    }
    .hero {
        width: 100%;
        height: max-content;
        margin-top: var(--hero-margin-top);
        /* row-gap: 10px; */
        grid-template-columns: 1fr;
        grid-template-rows: 1fr max-content max-content max-content;
    }
    .backdrop {
        grid-row: 1;
        grid-column: 1;
        width: 113vw;
        height: 69vw;
        top: calc(-1 * var(--hero-margin-top));
    }
    .video {
        grid-row: 1;
        grid-column: 1;
        justify-self: center;
        align-self: center;
    }
    .deck {
        grid-row: 2;
        grid-column: 1;
        margin-top: var(--deck-margin-top);
    }
    .title {
        grid-row: 3;
        grid-column: 1;
    }
    .body {
        grid-row: 4;
        grid-column: 1;
    }
}

@media(max-width: 768px) {
    .body {
        padding: 0px;
    }
}