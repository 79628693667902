.countdown_banner {
    --max-width: 2100px;
    --height: 459px;
    --padding-RL: 56px;
    --title-font-size: 35px;
    --subtitle-font-size: 18px;
}

@media(max-width: 2100px) {
    .countdown_banner {
        --max-width: 100%;
    }
}

.countdown_banner {
    width: 100vw;
    height: max-content;
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-columns: 1fr var(--max-width) 1fr;
}

.countdown_banner::before {
    grid-row: 1;
    grid-column: 1/4;
    content: '';
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, #85AD1E 43%, #FFFFFF00 100%, #85AD1E 100%);
}

.countdown_banner::after {
    grid-row: 1;
    grid-column: 1/4;
    content: '';
    width: 100%;
    height: 100%;
    background: #85AD1E;
    mix-blend-mode: multiply;
    z-index: -1;
}

.banner_image {
    grid-row: 1;
    grid-column: 2;
    justify-self: flex-end;
    width: 65%;
    height: var(--height);
    object-fit: cover;
    filter: grayscale(1);
    z-index: -1;
}

.countdown {
    grid-row: 1;
    grid-column: 2;
    justify-self: flex-start;
    display: grid;
    row-gap: 10px;
    padding: 0 var(--padding-RL);
}

.event_title {
    width: 100%;
    font-family: Lato;
    font-weight: 500;
    font-size: var(--title-font-size);
    color: #FFFFFF;
    white-space: pre-line;
}

.date {
    font-family: Lato;
    font-weight: bold;
    font-size: var(--subtitle-font-size);
    color: #FFFFFF;
    padding-bottom: 20px;
}

.sub_title {
    font-family: Lato;
    font-weight: bold;
    font-size: var(--subtitle-font-size);
    text-transform: capitalize;
    color: #FFFFFF;
}

@media(max-width: 1000px) {
    .countdown_banner {
        --padding-RL: calc(34px + (56 - 34) * ((100vw - 769px) / (1000 - 769)));
    }
}

@media(max-width: 768px) {
    .countdown {
        padding: 15px var(--padding-RL);
    }
    .countdown_banner {
        --height: calc(320px + (459 - 320) * ((100vw - 375px) / (768 - 375)));
        --padding-RL: calc(15px + (34 - 15) * ((100vw - 375px) / (768 - 375)));
        --title-font-size: calc(26px + (35 - 26) * ((100vw - 375px) / (768 - 375)));
        --subtitle-font-size: calc(16px + (18 - 16) * ((100vw - 375px) / (768 - 375)));
    }
}