.ripple {
    margin-top: 20px;
    display: inline-block;
    position: relative;
    height: 80px;
}

.ripple::before, .ripple::after {
    content: "";
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translate(-50%, -50%);
    border: 4px solid #85AD1E;
    border-radius: 50%;
    animation: ripple 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.ripple::after {
    animation-delay: -1s;
}

@keyframes ripple {
    0% {
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        width: 80px;
        height: 80px;
        opacity: 0;
    }
}