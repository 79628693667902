@media only screen and (min-width: 1681px) {
    .event_page {
        --video-feed-width: calc((16 / 7.72) * var(--video-feed-height));
        --video-feed-height: min(calc(100vh - 160px), calc((7.72 / 16) * 1680px));
    }
}

@media only screen and (max-width: 1680px) {
    .event_page {
        --video-feed-width: calc((16 / 7.34) * var(--video-feed-height));
        --video-feed-height: min(calc(100vh - 160px), calc((7.34 / 16) * 100vw));
    }
}

.event_page {
    width: 100vw;
    height: 100vh;
    display: grid;
    align-items: flex-start;
    grid-template-columns: 1fr var(--video-feed-width) 1fr;
    grid-template-rows: max-content 1fr var(--video-feed-height) 1fr max-content;
}