.header {
    --header-height: 100px;
    --logo-height: 53px;
    --text-font-size: 16px;
}

.header {
    grid-row: 1;
    width: 100vw;
    height: var(--header-height);
    background-color: #f1f3f5cc;
    backdrop-filter: saturate(180%) blur(5px);
    padding: 0px 20px;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-auto-flow: column;
    grid-column-gap: 20px;
    column-gap: 20px;
    grid-template-columns: max-content max-content max-content 1fr;
    top: 0;
    position: sticky;
    z-index: 2;
}

.header p {
    font-family: "roboto", sans-serif;
    font-weight: 400;
    font-style: italic;
    font-size: var(--text-font-size);
}

.logo {
    height: var(--logo-height);
}

@media(max-width: 425px) {
    .header {
        --header-height: calc(60px + (100 - 60) * ((100vw - 320px) / (425 - 320)));
        --logo-height: calc(34px + (53 - 34) * ((100vw - 320px) / (425 - 320)));
        --text-font-size: calc(14px + (16 - 14) * ((100vw - 320px) / (425 - 320)));
    }
}