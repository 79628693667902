.breakout_room {
    grid-row: 3;
    grid-column: 2;
    width: 100%;
    height: 100%;
    background-color: #F1F3F5;
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    overflow: hidden;
}