.modal>div {
    width: max-content;
    max-width: 550px;
    height: max-content;
    padding: 40px;
    display: grid;
    row-gap: 5px;
    justify-items: flex-start;
    position: relative;
}

.total_participants {
    font-family: Lato;
    font-weight: 700;
    font-size: 14px;
}

.close_modal {
    position: absolute;
    top: 15px;
    right: 15px;
    border: none;
    background: transparent;
}

.close_icon path {
    stroke: gray
}

.item {
    width: 100%;
    font-family: Lato;
    font-weight: 700;
    font-size: 14px;
    display: grid;
    column-gap: 10px;
    align-items: center;
    justify-items: flex-start;
    grid-template-columns: 1fr 50px;
    cursor: pointer;
}

.item input {
    width: 40px;
    height: 20px;
    padding-left: 5px;
}

.item:last-of-type {
    margin-bottom: 20px;
}

.button {
    justify-self: center;
    width: 250px;
    display: grid;
    justify-items: center;
}

.error_message {
    width: max-content;
    height: max-content;
    max-width: 360px;
    margin-top: 10px;
    font-family: Lato;
    font-weight: 400;
    font-size: 18px;
    color: #C0372A;
}