.notification {
    padding-left: 10px;
    margin-bottom: 10px;
    background: #FFFFFF;
    box-shadow: 0px 0px 6px 0px rgb(0 0 0 / 23%);
    border-radius: 8px 0px 0px 8px;
    cursor: pointer;
    pointer-events: all;
    display: grid;
    row-gap: 5px;
    column-gap: 10px;
    align-items: center;
    grid-template-columns: max-content 1fr;
}

.bubble {
    padding: 10px;
    border-radius: 4px;
    background: #FFFFFF;
    box-shadow: 0px 0px 12px rgb(0 0 0 / 13%);
    display: grid;
    gap: 5px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: max-content 1fr;
}

.name {
    grid-row: 1;
    grid-column: 1;
    font-weight: 600;
    font-size: 12px;
    color: #85AD1E;
    text-transform: capitalize;
}

.time {
    grid-row: 1;
    grid-column: 2;
    justify-self: flex-end;
    font-weight: 500;
    font-size: 10px;
    color: #545050;
    text-transform: uppercase;
}

.content {
    grid-row: 2;
    grid-column: 1/3;
    font-weight: 400;
    font-size: 12px;
    color: #000000;
    overflow-wrap: break-word;
}

.avatar {
    width: 34px;
    height: 34px;
    border-radius: 50%;
}