.registration_page {
    width: 100vw;
    height: 100%;
    display: grid;
    justify-items: center;
    align-items: flex-start;
    grid-template-columns: 1fr;
    grid-template-rows: max-content 1fr;
}

.body {
    width: 100vw;
    max-width: 2100px;
    height: 100%;
    display: grid;
    justify-items: center;
    align-items: flex-start;
    grid-template-columns: 1fr 1fr;
}

@media(max-width: 768px) {
    .body {
        grid-template-columns: 1fr;
        grid-auto-rows: max-content;
    }
}