.meeting_room {
    grid-row: 3;
    grid-column: 2;
    width: 100%;
    height: 100%;
    position: relative;
    padding: 16px;
    background-color: #F1F3F5;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    align-items: center;
}

.room_1, .room_2 {
    row-gap: 2.45%;
}

.room_3 {
    row-gap: 5%;
}

.room_4 {
    column-gap: 2.36%;
    flex-direction: row;
}