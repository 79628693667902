.button {
    width: 30px;
    height: 30px;
    border: none;
    border-radius: 50%;
    background: #2F2F2F;
    display: grid;
    align-items: center;
    justify-items: center;
    filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.25));
}

.button:active {
    background: #85AD1E;
}

.button:disabled {
    opacity: 1;
}

.icon {
    height: 30px;
    width: 30px;
}