.text_input {
    width: 100%;
    height: max-content;
    display: grid;
    row-gap: 8px;
}

.label {
    width: 100%;
    height: max-content;
    font-family: Lato;
    font-weight: 400;
    font-size: 18px;
    color: #000000;
}

.input {
    width: 100%;
    height: 56px;
    border: 1px solid #ff000000;
    padding: 15px;
    font-family: Lato;
    font-weight: 400;
    font-size: 18px;
}

.error_input {
    background-color: #FFE7E7;
    border: 1px solid #FF0000;
}

.input_field::placeholder, .error_input_field::placeholder {
    color: rgb(255, 255, 255);
    font-weight: 300;
    font-size: 15px;
}

.input_field:active {
    outline: none;
}

.error {
    width: 100%;
    font-family: Lato;
    font-weight: 400;
    font-size: 18px;
    color: #FF0000;
}