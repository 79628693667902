.register_form {
    --form-padding-R: 40px;
    --form-padding-L: 106px;
}

.register_form {
    grid-column: 2;
    width: 100%;
    height: 100%;
    background: #E1EDCC;
    padding: 40px var(--form-padding-R) 40px var(--form-padding-L);
}

.register_success {
    min-height: 700px;
    display: grid;
    justify-content: center;
    align-content: center;
    justify-items: center;
}

.wrapper {
    justify-self: flex-start;
    width: 100%;
    max-width: 441px;
    height: 100%;
    display: grid;
    row-gap: 32px;
    grid-auto-rows: max-content;
}

.title {
    font-family: Lato;
    font-weight: 600;
    font-size: 20px;
    color: #000000;
}

.details {
    font-family: Lato;
    font-weight: 400;
    font-size: 16px;
    color: #2F2F2F;
}

.form {
    display: grid;
    row-gap: 18px;
}

.error_message {
    width: 100%;
    height: max-content;
    font-family: Lato;
    font-weight: 400;
    font-size: 18px;
    color: #FFFFFF;
    background: #C0372A;
    border-radius: 2px;
    padding: 10px 10px;
}

.tc_privacy {
    display: grid;
    column-gap: 8px;
    grid-template-columns: max-content 1fr;
}

.tc_checkbox {
    grid-row: 1;
    grid-column: 1;
    width: 23px;
    height: 23px;
    background: #ffffff;
    border: 1px solid #443F3F;
    -moz-appearance:none;
    -webkit-appearance:none;
    -o-appearance:none;
}

.tc_checkbox:checked {
    -moz-appearance:checkbox;
    -webkit-appearance:checkbox;
    -o-appearance:checkbox;
}

.not_checked {
    background:#FFE7E7;
    border: 1px solid #C0372A;
}

.tc_text {
    grid-row: 1;
    grid-column: 2;
    width: 80%;
    font-family: Lato;
    font-weight: 400;
    font-size: 14px;
    color: #2F2F2F;
}

.tc_text a {
    font-family: Lato;
    font-weight: 700;
    font-size: 14px;
    color: #85AD1E;
    text-decoration: none;
}

.tc_error {
    grid-row: 2;
    grid-column: 1/3;
    width: 100%;
    font-family: Lato;
    font-weight: 400;
    font-size: 18px;
    color: #FF0000;
}

.celebrate {
    background-color: #D4ED92;
    width: 118px;
    height: 118px;
    padding: 10px;
    border-radius: 50%;
}

@media(max-width: 1680px) {
    .register_form {
        --form-padding-R: calc(20px + (40 - 20) * ((100vw - 769px) / (1680 - 769)));
        --form-padding-L: calc(36px + (106 - 36) * ((100vw - 769px) / (1680 - 769)));
    }
}

@media(max-width:768px) {
    .register_form {
        --form-padding-R: calc(20px + (40 - 20) * ((100vw - 376px) / (768 - 376)));
        --form-padding-L: calc(20px + (40 - 20) * ((100vw - 376px) / (768 - 376)));
    }
    .register_form {
        grid-column: 1;
    }
    .wrapper {
        max-width: 100%;
    }
    .details {
        max-width: 441px;
    }
}