.previous_connections {
    --previous-connection-row-gap: 60px;
    --previous-connections-list-gap: 33px;
    --previos-connection-padding-B: 72px;
    --previous-connection-title-font-size: 48px;
}

.previous_connections {
    position: relative;
    width: 100%;
    max-width: 1680px;
    height: max-content;
    padding: 0 var(--discovery-page-padding) var(--previos-connection-padding-B) var(--discovery-page-padding);
    display: grid;
    grid-template-rows: repeat(2,max-content);
    row-gap: var(--previous-connection-row-gap);
}
.prev,.next {
    position: absolute;
    top: 30%;
    z-index: 2;
    width: 50px;
    height: 50px;
    display: grid;
    justify-items: center;
    align-items: center;
    border: none;
    border-radius: 100px;
    background: rgb(241, 243, 245);
}
.prev {left: calc(var(--discovery-page-padding) / 2);}
.next {right: calc(var(--discovery-page-padding) / 2);}
.prev_icon, .next_icon {
    width: 13px;
    height: 23px;
}
.prev_icon {transform: rotate(-90deg);}
.prev_icon path {stroke: rgb(47, 47, 47);}
.next_icon {transform: rotate(90deg);}
.next_icon path {stroke: rgb(47, 47, 47);}
.loading {
    width: 23px;
    height: 23px;
    animation: rotateWithBoost 1.5s linear infinite;
}
.loading path {fill: rgb(47, 47, 47);}
@keyframes rotateWithBoost {
    0% {transform: rotate(0deg);}
    100% {transform: rotate(360deg);}
}

.connections_list {
    padding-bottom: 20px;
    display: grid;
    grid-auto-flow: column;
    grid-auto-columns: max-content;
    grid-template-rows: max-content;
    gap: var(--previous-connections-list-gap);
    justify-items: flex-start;
    align-items: flex-start;
}

.previous_connection_title {
    font-family: Lato;
    font-size: var(--previous-connection-title-font-size);
    font-weight: 500;
    line-height: calc(var(--previous-connection-title-font-size) + 8px);
}

@media(max-width: 1680px) {
    .previous_connections {
        --previous-connection-row-gap: calc(24px + (60 - 24) * ((100vw - 769px) / (1680 - 769)));
        --previous-connections-list-gap: calc(16px + (33 - 16) * ((100vw - 769px) / (1680 - 769)));
        --previos-connection-padding-B: calc(40px + (72 - 40) * ((100vw - 769px) / (1680 - 769)));
        --previous-connection-title-font-size: calc(36px + (48 - 36) * ((100vw - 769px) / (1680 - 769)));
    }
}

@media(max-width: 1023px) {
    .prev {left: calc(var(--discovery-page-padding) / 3);}
    .next {right: calc(var(--discovery-page-padding) / 3);}
}

@media(max-width: 768px) {
    .previous_connections {
        --previous-connection-row-gap: calc(16px + (24 - 16) * ((100vw - 376px) / (768 - 376)));
        --previous-connections-list-gap: calc(12px + (16 - 12) * ((100vw - 376px) / (768 - 376)));
        --previos-connection-padding-B: calc(32px + (40 - 32) * ((100vw - 376px) / (768 - 376)));
        --previous-connection-title-font-size: calc(26px + (36 - 26) * ((100vw - 376px) / (768 - 376)));
    }
    .connections_list {
        overflow: hidden;
        overflow-x: auto;
    }
    .prev,.next{display: none;}
}

@media(max-width: 375px) {
    .previous_connections {
        --previous-connection-row-gap: 16px;
        --previous-connection-title-font-size: 26px;
    }
}