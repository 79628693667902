.messages {
    height: 100%;
    padding-right: 13px;
    display: flex;
    flex-direction: column-reverse;
    overflow-y: auto;
}

.no_messages {
    display: grid;
    justify-content: center;
    align-content: center;
    justify-items: center;
}

.wave_hand {
    padding: 30px;
    width: 118px;
    height: 118px;
    border-radius: 50%;
    background-color: #D4ED92;
}