.menu_settings {
    width: max-content;
    height: max-content;
}

.title {
    font-family: Lato;
    font-weight: 700;
    font-size: 14px;
}

.item {
    font-family: Lato;
    font-weight: 400;
    font-size: 14px;
    display: grid;
    gap: 5px;
    align-items: center;
    justify-items: flex-start;
    grid-template-columns: max-content 1fr;
    cursor: pointer;
}

.item input {
    width: 16px;
    height: 16px;
}