.play_button {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background-color: #85AD1E;
    background-position: center;
    transition: all 0.8s;
    cursor: pointer;
    z-index: 1;
    display: grid;
    justify-items: center;
    align-items: center;
}

.play_button:hover {
    background: #85AD1E radial-gradient(circle, transparent 1%, #85AD1E 1%) center/15000%;
}

.play_button:active {
    background-color: #9bd800;
    background-size: 100%;
    transition: all 0s;
}

.play_button::after {
    content: "";
    margin-left: 10px;
    border-top: 20px solid transparent;
    border-left: 30px solid #F1F3F5;
    border-bottom: 20px solid transparent;
}

.white {
    background-color: #FFFFFF;
}

.white:hover {
    background: #F1F3F5 radial-gradient(circle, transparent 1%, #F1F3F5 1%) center/15000%;
}

.white:active {
    background-color: #dadada;
}

.white::after {
    border-left: 30px solid #85AD1E;
}

@media(max-width: 700px) {
    .play_button {
        width: 60px;
        height: 60px;
    }
    .play_button::after {
        margin-left: 5px;
        border-top: 10px solid transparent;
        border-left: 20px solid #FFFFFF;
        border-bottom: 10px solid transparent;
    }
    .white::after {
        border-left: 20px solid #85AD1E;
    }
}