.waiting_room {
    --width: var(--video-feed-width);
    --padding: calc(10px + (30 - 10) * ((var(--width) - 375px) / (1680 - 375)));
    --title-font-size: calc(20px + (40 - 20) * ((var(--width) - 375px) / (1680 - 375)));
    --subtitle-font-size: calc(16px + (25 - 16) * ((var(--width) - 375px) / (1680 - 375)));
    --count-font-size: calc(20px + (40 - 20) * ((var(--width) - 375px) / (1680 - 375)));
    --unitFontSize: calc(8px + (16 - 8) * ((var(--width) - 375px) / (1680 - 375)));
}

.waiting_room {
    grid-row: 3;
    grid-column: 2;
    width: 100%;
    height: 100%;
    padding: var(--padding);
    background-color: #85AD1E;
    display: grid;
    row-gap: 30px;
    grid-template-columns: 1fr;
    grid-template-rows: max-content 1fr;
}

.show_button {
    grid-template-rows: max-content max-content 1fr;
}

.countdown {
    justify-self: flex-start;
    display: grid;
    row-gap: 10px;
}

.event_title {
    width: 100%;
    font-family: Lato;
    font-weight: 500;
    font-size: var(--title-font-size);
    color: #FFFFFF;
}

.date {
    font-family: Lato;
    font-weight: bold;
    font-size: var(--subtitle-font-size);
    color: #FFFFFF;
}

.sub_title {
    font-family: Lato;
    font-weight: bold;
    font-size: var(--subtitle-font-size);
    text-transform: capitalize;
    color: #FFFFFF;
}

.start_event {
    background: #F3C716;
    color: #2F2F2F;
}

.countdown_clock {
    height: max-content;
    width: max-content;
}

.countdown_clock div {
    height: max-content;
    width: max-content;
    grid-template-columns: 1fr;
    row-gap: 8px;
}

.countdown_clock div:first-child {
    width: max-content;
    height: max-content;
    font-size: var(--count-font-size);
    line-height: calc(var(--count-font-size) + 10px);
}

.countdown_clock div:last-child {
    width: max-content;
    height: max-content;
    margin: 0px;
    font-size: var(--unitFontSize);
    line-height: var(--unitFontSize);
}