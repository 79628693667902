.header {
    --header-gap: 20px;
    --event-page-logo-height: 53px;
    --button-font-size: 14px;
}

.header {
    grid-row: 1;
    grid-column: 1/4;
    width: 100%;
    height: 100px;
    padding: 0px 20px;
    background-color: #ffffff;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-auto-flow: column;
    grid-column-gap: var(--header-gap);
    column-gap: var(--header-gap);
    grid-template-columns: repeat(3, max-content) 1fr;
}

.partnership_text {
    font-family: "roboto", sans-serif;
    font-weight: 400;
    font-style: italic;
    font-size: 12px;
    color: rgb(0, 0, 0);
}

.logo {
    height: var(--event-page-logo-height);
}

.info_panel {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-rows: 1fr;
    gap: 18px;
    justify-items: flex-end;
    align-items: center;
}

.menu {
    grid-row: 1;
    grid-column: 1;
    width: max-content;
    display: grid;
    grid-auto-flow: column;
    grid-auto-rows: max-content;
    column-gap: 20px;
}

.button {
    height: max-content;
    outline: none;
    border: none;
    background: transparent;
    border-bottom: 1px solid rgba(0, 0, 0, 0);
    color: rgb(0, 0, 0);
    font-size: var(--button-font-size);
    text-transform: uppercase;
    display: grid;
    gap: 10px;
    align-items: center;
    grid-auto-flow: column;
}

.underling {
    font-weight: 700;
    border-bottom: 1px solid rgb(0, 0, 0);
}

.info_icon path {
    fill: rgb(133, 173, 30);
}

.info_card {
    width: 65%;
    max-width: 90%;
    max-height: 700px;
    background: rgba(241, 243, 245, 0.95);
    display: grid;
    grid-auto-rows: max-content 1fr;
    row-gap: 20px;
}

@media(max-width: 768px) {
    .menu {
        grid-auto-flow: row;
        justify-items: flex-end;
    }
}

@media(max-width: 1200px) {
    .header {
        --event-page-logo-height: calc(38px + (53 - 38) * ((100vw - 769px) / (1200 - 769)));
    }
}

@media(max-width: 768px) {
    .header {
        --header-gap: calc(10px + (20 - 10) * ((100vw - 426px) / (768 - 426)));
        --event-page-logo-height: calc(28px + (38 - 28) * ((100vw - 426px) / (768 - 426)));
        --button-font-size: calc(12px + (14 - 12) * ((100vw - 426px) / (768 - 426)));
    }
}

@media(max-width: 425px) {
    .header {
        --event-page-logo-height: calc(24px + (28 - 24) * ((100vw - 375px) / (425 - 375)));
    }
}