* {
  padding: 0;
  margin: 0;
  font-family: lato, sans-serif;
  box-sizing: border-box;
}

html, body, #root {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

#root {
  overflow-y: auto;
  display: grid;
  justify-items: center;
  grid-template-rows: max-content 1fr max-content;
}

button {
  cursor: pointer;
  user-select: none;
}

button:active {
  opacity: 0.7;
}

button:disabled {
  cursor: not-allowed;
}

img, svg {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}