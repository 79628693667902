.button {
    width: max-content;
    padding: 5px 20px;
    background: #85AD1E;
    border: none;
    border-radius: 2px;
    filter: drop-shadow(1px 1px 4px rgba(0, 0, 0, 0.25));
    font-weight: 600;
    font-size: 20px;
    color: #ffff;
}