.message {
    justify-self: flex-start;
    margin-bottom: 15px;
    display: grid;
    grid-template-columns: max-content 1fr;
    align-items: flex-end;
    column-gap: 10px;
    row-gap: 5px;
}

.message:first-child {
    margin-bottom: 0px;
}

.bubble {
    background: #FFFFFF;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.04);
    border-radius: 4px;
    padding: 10px;
    display: grid;
    gap: 5px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: max-content 1fr;
}

.name {
    grid-row: 1;
    grid-column: 1;
    font-weight: 600;
    font-size: 12px;
    color: #85AD1E;
    text-transform: capitalize;
}

.time {
    grid-row: 1;
    grid-column: 2;
    justify-self: flex-end;
    font-weight: 500;
    font-size: 10px;
    color: #545050;
    text-transform: uppercase;
}

.status {
    grid-row: 2;
    grid-column: 2;
    justify-self: flex-end;
    align-self: flex-end;
    width: 12px;
    height: 12px;
}

.try_again {
    width: max-content;
    border: none;
    background-color: none;
    font-family: Lato;
    font-style: italic;
    font-weight: 400;
    font-size: 12px;
    color: #545050;
    display: grid;
    align-items: center;
    justify-items: center;
    column-gap: 5px;
}

.try_again .status {
    grid-row: 1;
    grid-column: 2;
    align-self: center;
    justify-self: center;
}

.content {
    grid-row: 2;
    grid-column: 1/3;
    font-weight: 400;
    font-size: 12px;
    color: #000000;
    overflow-wrap: break-word;
}

.avatar {
    width: 34px;
    height: 34px;
    border-radius: 50%;
}

.is_user {
    justify-self: flex-end;
    grid-template-columns: 1fr max-content;
}

.is_user .bubble {
    grid-row: 1;
    grid-column: 1;
}

.is_user .avatar {
    grid-row: 1;
    grid-column: 2;
}