.video_highlight {
    --video-height: calc((9 / 16) * (100vw / 2));
    --deck-font-size: 20px;
    --title-font-size: 59px;
    --body-font-size: 22px;
}

.video_highlight {
    width: 100%;
    max-width: 2100px;
    height: max-content;
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.description {
    background-color: #F1F3F5;
    padding-right: 20px;
    padding-left: 80px;
    display: grid;
    align-items: center;
    align-content: center;
    grid-template-rows: max-content max-content max-content;
}

.deck {
    font-family: Lato;
    font-weight: 300;
    font-size: var(--deck-font-size);
    line-height: 29px;
    text-transform: capitalize;
    color: #686262;
}

.title {
    font-family: Lato;
    font-weight: 500;
    font-size: var(--title-font-size);
    line-height: calc(var(--title-font-size) + 10px);
    color: #000000;
    padding-bottom: 20px;
}

.body {
    padding-right: 30%;
    font-family: Lato;
    font-weight: 400;
    font-size: var(--body-font-size);
    color: #2F2F2F;
}

.video {
    height: var(--video-height);
}

@media(min-width:2101px) {
    .video_highlight {
        --video-height: 590.625px;
    }
}

@media(max-width: 1680px) {
    .video_highlight {
        --deck-font-size: calc(12px + (20 - 12) * ((100vw - 1024px) / (1680 - 1024)));
        --title-font-size: calc(36px + (59 - 36) * ((100vw - 1024px) / (1680 - 1024)));
        --body-font-size: calc(13px + (22 - 13) * ((100vw - 1024px) / (1680 - 1024)));
    }
}

@media(max-width: 1023px) {
    .video_highlight {
        --video-height: calc((9 / 16) * 100vw);
        --deck-font-size: calc(17px + (20 - 17) * ((100vw - 320px) / (1023 - 320)));
        --title-font-size: calc(25px + (36 - 25) * ((100vw - 320px) / (1023 - 320)));
        --body-font-size: calc(15px + (22 - 15) * ((100vw - 320px) / (1023 - 320)));
    }
    .video_highlight {
        grid-template-columns: 1fr;
        grid-template-rows: max-content max-content;
    }
    .video {
        grid-row: 1;
        grid-column: 1;
    }
    .description {
        padding: 40px 20px;
    }
    .deck {
        grid-column: 1;
        padding-bottom: 10px;
    }
    .title {
        grid-column: 1;
    }
    .body {
        grid-row: 4;
        grid-column: 1;
    }
}

@media(max-width: 768px) {
    .body {
        padding-right: 0px;
    }
}