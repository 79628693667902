.invited_guests {
    background: transparent;
}

.invited_guests>div {
    width: 100vw;
    max-width: 100vw;
    height: 100vh;
    border-radius: 0;
    background: rgba(241, 243, 245, 0.95);
    display: grid;
    grid-template-rows: max-content 1fr;
    row-gap: 12px;
    overflow: hidden;
    z-index: 1;
    text-align: left;
    color: rgb(0, 0, 0);
}

.guests {
    width: 100%;
    height: 100%;
    padding-right: 15px;
    display: grid;
    gap: 40px;
    align-content: flex-start;
    overflow-y: auto;
}

.close_button {
    grid-row: 1;
    justify-self: flex-end;
    border: none;
    background: transparent;
    padding-bottom: 38px;
}

.close_icon path {
    stroke: rgb(0, 0, 0);
}