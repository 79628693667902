.share {
    width: max-content;
    position: relative;
    display: grid;
    row-gap: 10px;
    grid-auto-flow: row;
    justify-items: center;
}

.social_medias {
    width: max-content;
    height: max-content;
    position: absolute;
    bottom: 50px;
    display: grid;
    row-gap: 5px;
    grid-auto-flow: row;
    justify-items: center;
    z-index: 1;
}

.social_button {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: none;
    background-color: #2F2F2F;
    display: grid;
    align-items: center;
    justify-items: center;
}

.social_icon {
    width: 100%;
}

.button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    border: none;
    background-color: #85AD1E;
    display: grid;
    align-items: center;
    justify-items: center;
}

.share_icon {
    width: 21.33px;
    height: 21.33px;
}