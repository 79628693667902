.end_call {
    justify-self: center !important;
}

.end_call>button {
    background: #C0372A;
}

.button {
    width: 120px;
    font-size: 14px;
    padding: 3px 10px;
    background: #C0372A;
}

.modal_buttons {
    width: 100%;
    margin-top: 50px;
    display: grid;
    gap: 10px;
    grid-template-columns: 1fr 1fr;
    justify-content: space-between;
}

.modal_buttons>button {
    width: 100%;
}

.error_message {
    width: max-content;
    height: max-content;
    max-width: 360px;
    margin-top: 10px;
    font-family: Lato;
    font-weight: 400;
    font-size: 18px;
    color: #C0372A;
}