.login_page {
    width: 100vw;
    height: 100%;
    display: grid;
    justify-items: center;
    align-items: flex-start;
    grid-template-columns: 1fr;
    grid-template-rows: max-content 1fr;
}

.body {
    width: 100vw;
    max-width: 1680px;
    height: 100%;
    display: grid;
    justify-items: center;
    align-items: flex-start;
    grid-template-columns: 1fr 1fr;
}