.register_form {
    grid-column: 2;
    width: 100%;
    height: 100%;
    background: #E1EDCC;
    padding: 40px 40px 40px 106px;
}

.wrapper {
    width: 100%;
    max-width: 441px;
    height: 100%;
    display: grid;
    row-gap: 32px;
    grid-auto-rows: max-content;
}

.title {
    font-family: Lato;
    font-weight: 600;
    font-size: 20px;
    color: #000000;
}

.details {
    font-family: Lato;
    font-weight: 400;
    font-size: 16px;
    color: #2F2F2F;
}

.form {
    display: grid;
    row-gap: 18px;
}

.error_message {
    width: 100%;
    height: max-content;
    font-family: Lato;
    font-weight: 400;
    font-size: 18px;
    color: #FFFFFF;
    background: #C0372A;
    border-radius: 2px;
    padding: 10px 10px;
}

.tc_privacy, .allow_record {
    display: grid;
    column-gap: 8px;
    grid-template-columns: max-content 1fr;
}

.tc_checkbox {
    grid-row: 1;
    grid-column: 1;
    width: 23px;
    height: 23px;
    background: #ffffff;
    border: 1px solid #443F3F;
    -moz-appearance:none;
    -webkit-appearance:none;
    -o-appearance:none;
}

.tc_checkbox:checked {
    -moz-appearance:checkbox;
    -webkit-appearance:checkbox;
    -o-appearance:checkbox;
}

.not_checked {
    background:#FFE7E7;
    border: 1px solid #C0372A;
}

.tc_text {
    grid-row: 1;
    grid-column: 2;
    width: 80%;
    font-family: Lato;
    font-weight: 400;
    font-size: 14px;
    color: #2F2F2F;
}

.tc_text a {
    font-family: Lato;
    font-weight: 700;
    font-size: 14px;
    color: #85AD1E;
    text-decoration: none;
}

.tc_error {
    grid-row: 2;
    grid-column: 1/3;
    width: 100%;
    font-family: Lato;
    font-weight: 400;
    font-size: 18px;
    color: #FF0000;
}