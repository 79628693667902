.notifications {
    position: fixed;
    top: 0px;
    right: 0px;
    width: 350px;
    padding-top: 120px;
    padding-left: 10px;
    height: calc(100vh - (170px));
    overflow: hidden;
}

.notifications:last-child {
    margin-bottom: 0rem;
}