:root {
    --tawk-to-button-size: 3.75rem;
}

.tawk_to {
    width: 145px;
    height: max-content;
    position: fixed;
    bottom: 0px;
    right: 10px;
    z-index: 100;
}

.open {
    width: 280px;
}

.loading {
    width: 280px;
    height: 450px;
}

.iframe_wrapper {
    width: 100%;
    height: 450px;
    background-color: #fff;
    display: none;
}

.open .iframe_wrapper {
    display: block;
}

.iframe {
    width: 100%;
    height: 100%;
}

.button {
    width: 100%;
    height: 40px;
    background-color: rgb(0, 0, 0);
    border-top-left-radius: 10px;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 0px 12px;
    display: grid;
    grid-auto-flow: column;
    grid-template-columns: max-content 1fr max-content;
    justify-items: center;
    align-items: center;
}

.button_text {
    font-style: normal;
    font-weight: bold;
    font-size: 15px;
    color: #ffffff;
    padding-left: 15px;
}

.message_icon {
    width: 24px;
}

.arrow_icon {
    width: 20px;
    transform: rotate(0deg);
}

.arrow_icon path {
    stroke: rgb(133, 173, 30);
}