.participant {
    width: 100%;
    height: 100%;
    position: relative;
    border-radius: 4px;
    background: #D4DAC7;
    filter: drop-shadow(1px 1px 6px #00000026);
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.name {
    position: absolute;
    right: 0;
    bottom: 0;
    padding-right: 10px;
    font-size: 16px;
    color: #ffffff;
    background-color: #00000099;
    display: grid;
    justify-items: center;
    align-items: center;
    grid-template-columns: max-content 1fr;
    z-index: 1;
}

.mic {
    width: 30px;
}

.raised_hand {
    width: 25px;
    position: absolute;
    top: 0;
    left: 0;
    margin-left: 10px;
    margin-top: 10px;
    z-index: 1;
}

.video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.hide {
    display: none;
}

.avatar {
    width: 30%;
    border-radius: 50%;
    object-fit: cover;
}