.loading {
    grid-row: 2;
    width: 100vw;
    height: 100vh;
    display: flex;
    row-gap: 10px;
    align-items: center;
    justify-items: center;
    flex-direction: column;
    justify-content: center;
    background-color: #85AD1E;
}

.message {
    font-weight: 500;
    font-size: 25px;
    color: #ffffff;
    text-align: center;
}