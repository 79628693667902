.guest {
    display: grid;
    gap: 40px;
    grid-template-columns: max-content 1fr;
    justify-items: center;
    align-items: flex-start;
}

.guest:last-child .info {
    margin-bottom: 50px;
}

.image {
    width: 100px;
    height: 100px;
    object-fit: cover;
    border-radius: 50%;
}

.info {
    font-weight: 400;
    font-size: 16px;
}

@media(max-width: 425px) {
    .guest {
        display: grid;
        gap: 20px;
    }
    .image {
        width: 64px;
        height: 64px;
    }
    .info {font-size: 14px;}
}