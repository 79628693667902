.participant_chat {
    grid-row: 2/5;
    grid-column: 2/4;
    justify-self: flex-end;
    width: 400px;
    height: 100%;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: 1fr 1fr;
    overflow: hidden;
    pointer-events: none;
}

.container {
    grid-row: 1;
    grid-column: 2;
    width: max-content;
    height: 100%;
    display: grid;
    align-items: flex-end;
    grid-template-rows: 1fr;
    grid-template-columns: max-content 1fr;
    position: relative;
    right: 0;
    transition: right 300ms;
    overflow: hidden;
    pointer-events: all;
}

.hide {
    right: -380px;
}

.chat {
    width: 380px;
    height: 100%;
    background: #F1F3F5;
    padding: 10px 3px 16px 16px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr max-content;
    row-gap: 10px;
    overflow: hidden;
}

.toggle {
    grid-row: 1;
    grid-column: 1;
    width: 20px;
    height: 100%;
    border: none;
    cursor: pointer;
    background: #2F2F2F;
    display: grid;
    align-items: center;
    justify-items: center;
    transition: opacity 300ms ease-in 1s;
}

.hide .toggle {
    opacity: 0.2;
}

.hide .toggle:hover {
    opacity: 1;
    transition: opacity 300ms ease-in 0s;
}

.icon {
    transform: rotate(90deg);
}

.hide .icon {
    transform: rotate(270deg);
}

.icon path {
    stroke: #fff
}