.upcoming_connection_card {
    --card-padding: 24px;
    --card-width: 490px;
    --image-height: calc((425.82 / 759) * var(--card-width));
    --title-font-size: 20px;
    --date-desc-button-font-size: 16px;
}

.upcoming_connection_card {
    width: var(--card-width);
    height: 100%;
    display: grid;
    grid-template-rows: var(--image-height) 1fr;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    background: #000;
}

.card_info {
    width: 100%;
    height: 100%;
    padding: var(--card-padding);
    border: 1px solid rgb(231, 231, 231);
    box-sizing: border-box;
    display: grid;
    grid-template-rows: repeat(2, max-content) 1fr max-content;
    justify-items: flex-start;
}

.date {
    font-family: Lato;
    font-weight: 500;
    font-size: var(--date-desc-button-font-size);
    line-height: calc(var(--date-desc-button-font-size) + 7px);
    color: rgb(104, 98, 98);
    padding-bottom: 8px;
}

.title {
    font-family: Lato;
    font-weight: 600;
    font-size: var(--title-font-size);
    line-height: calc(var(--title-font-size) + 4px);
    color: rgb(0, 0, 0);
    padding-bottom: 4px;
}

.short_description {
    width: 100%;
    height: calc((3 * var(--date-desc-button-font-size)) + 21px);
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    font-family: Lato;
    font-weight: 400;
    font-size: var(--date-desc-button-font-size);
    line-height: calc(var(--date-desc-button-font-size) + 7px);
    color: rgb(104, 98, 98);
}

.learn_more_button {
    padding-top: 14px;
    background: transparent;
    border: none;
    font-family: Lato;
    font-weight: 700;
    font-size: var(--date-desc-button-font-size);
    line-height: calc(var(--date-desc-button-font-size) + 7px);
    text-decoration: none;
    color: rgb(0, 0, 0);
}

@media(max-width: 1680px) {
    .upcoming_connection_card {
        --card-padding: calc(14px + (24 - 14) * ((100vw - 769px) / (1680 - 769)));
        --card-width: calc(284px + (490 - 284) * ((100vw - 1024px) / (1680 - 1024)));
        --title-font-size: calc(16px + (20 - 16) * ((100vw - 769px) / (1680 - 769)));
        --date-desc-button-font-size: calc(12px + (16 - 12) * ((100vw - 769px) / (1680 - 769)));
    }
}

@media(max-width: 1023px) {
    .upcoming_connection_card {
        --card-width: calc(224px + (298 - 224) * ((100vw - 769px) / (1023 - 769)));
    }
}

@media(max-width: 768px) {
    .upcoming_connection_card {
        --card-padding: calc(14px + (20 - 14) * ((100vw - 501px) / (768 - 501)));
        --card-width: calc(228px + (348 - 228) * ((100vw - 501px) / (768 - 501)));
        --title-font-size: calc(16px + (20 - 16) * ((100vw - 501px) / (768 - 501)));
        --date-desc-button-font-size: calc(12px + (16 - 12) * ((100vw - 501px) / (768 - 501)));
    }
}

@media(max-width: 500px) {
    .upcoming_connection_card {
        --card-padding: 20px;
        --card-width: 410px;
        --title-font-size: 20px;
        --date-desc-button-font-size: 16px;
    }
}

@media(max-width: 440px) {
    .upcoming_connection_card {
        --card-padding: calc(14px + (20 - 14) * ((100vw - 375px) / (440 - 375)));
        ;
        --card-width: calc(352px + (410 - 352) * ((100vw - 375px) / (440 - 375)));
        ;
        --title-font-size: calc(16px + (20 - 16) * ((100vw - 375px) / (440 - 375)));
        ;
        --date-desc-button-font-size: calc(12px + (16 - 12) * ((100vw - 375px) / (440 - 375)));
        ;
    }
}