.previous_connection_card {
    --card-padding-T: 13px;
    --card-width: 367px;
    --image-height: calc((375.21 / 558) * var(--card-width));
    --title-desc-button-font-size: 16px;
}

.previous_connection_card {
    width: var(--card-width);
    height: max-content;
    display: grid;
    grid-template-rows: var(--image-height) 1fr;
}

.image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card_info {
    width: 100%;
    height: 100%;
    padding: var(--card-padding-T) 4px 0 4px;
    border: none;
    display: grid;
    grid-template-rows: repeat(2, max-content);
    justify-items: flex-start;
}

.title {
    font-family: Lato;
    font-weight: 700;
    font-size: var(--title-desc-button-font-size);
    line-height: calc(var(--title-desc-button-font-size) + 4px);
    color: rgb(0, 0, 0);
    padding-bottom: 4px;
}

.short_description {
    width: 100%;
    height: 100%;
    font-family: Lato;
    font-weight: 400;
    font-size: var(--title-desc-button-font-size);
    line-height: calc(var(--title-desc-button-font-size) + 4px);
    color: rgb(0, 0, 0);
}

.learn_more_button {
    background: transparent;
    border: none;
    font-family: Lato;
    font-weight: 700;
    font-size: var(--title-desc-button-font-size);
    line-height: calc(var(--title-desc-button-font-size) + 4px);
    color: rgb(0, 0, 0);
    text-decoration: none;
}

@media(max-width: 1680px) {
    .previous_connection_card {
        --card-padding-T: calc(10px + (13 - 10) * ((100vw - 769px) / (1680 - 769)));
        --card-width: calc(212px + (367 - 212) * ((100vw - 1024px) / (1680 - 1024)));
        --title-desc-button-font-size: calc(12px + (16 - 12) * ((100vw - 769px) / (1680 - 769)));
    }
}

@media(max-width: 1023px) {
    .previous_connection_card {
        --card-width: calc(168px + (224 - 168) * ((100vw - 769px) / (1023 - 769)));
    }
}

@media(max-width: 768px) {
    .previous_connection_card {
        --card-padding-T: calc(10px + (13 - 10) * ((100vw - 376px) / (768 - 376)));
        --card-width: calc(237px + (290 - 237) * ((100vw - 376px) / (768 - 376)));
        --title-desc-button-font-size: calc(12px + (16 - 12) * ((100vw - 376px) / (768 - 376)));
    }
}

@media(max-width: 375px) {
    .previous_connection_card {
        --card-padding-T: 13px;
        --card-width: 237px;
        --title-desc-button-font-size: 12px;
    }
}