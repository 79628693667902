.about_event {
    --about-padding-RL: 56px;
}

.about_event {
    grid-column: 1;
    width: 100%;
    height: 100%;
    display: grid;
    grid-auto-rows: max-content;
    align-items: flex-start;
    row-gap: 13px;
    padding: 40px var(--about-padding-RL);
}

.title {
    font-family: Lato;
    font-weight: 600;
    font-size: 20px;
    color: #000000;
}

.details {
    font-family: Lato;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
}

.guests {
    display: grid;
    row-gap: 16px;
}

.guest {
    display: grid;
    grid-template-columns: max-content 1fr;
    column-gap: 16px;
}

.guest_image {
    grid-row: 1/3;
    grid-column: 1;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    object-fit: cover;
    filter: drop-shadow(1px 1px 6px rgba(0, 0, 0, 0.15));
}

.guest_name {
    grid-row: 1;
    grid-column: 2;
    align-self: flex-end;
    font-family: Lato;
    font-weight: 700;
    font-size: 16px;
    color: #000000;
}

.guest_title {
    grid-row: 2;
    grid-column: 2;
    align-self: flex-start;
    font-family: Lato;
    font-weight: 400;
    font-size: 16px;
    color: #000000;
}

@media(max-width: 1680px) {
    .about_event {
        --about-padding-RL: calc(28px + (56 - 28) * ((100vw - 769px) / (1680 - 769)));
    }
}

@media(max-width: 768px) {
    .about_event {
        --about-padding-RL: calc(20px + (40 - 20) * ((100vw - 376px) / (768 - 376)));
    }
}